import web1001 from './../assets/img/portfolio/web/1001.png';
import web1002 from './../assets/img/portfolio/web/1002.png';
import web1003 from './../assets/img/portfolio/web/1003.png';
// import web1004 from './../assets/img/portfolio/web/1004.png';
import web1005 from './../assets/img/portfolio/web/1005.png';
import web1006 from './../assets/img/portfolio/web/1006.png';
import web1007 from './../assets/img/portfolio/web/1007.png';
import web1008 from './../assets/img/portfolio/web/1008.png';
import web1009 from './../assets/img/portfolio/web/1009.png';
import web1010 from './../assets/img/portfolio/web/1010.png';
import web1011 from './../assets/img/portfolio/web/1011.png';
import web1012 from './../assets/img/portfolio/web/1012.png';
import web1013 from './../assets/img/portfolio/web/1013.png';
import web1014 from './../assets/img/portfolio/web/1014.png';
import web1015 from './../assets/img/portfolio/web/1015.png';
import web1016 from './../assets/img/portfolio/web/1016.png';
import web1017 from './../assets/img/portfolio/web/1017.png';
import web1018 from './../assets/img/portfolio/web/1018.png';
// ------------
import appA003 from './../assets/img/portfolio/app/A003.png';
import appA004 from './../assets/img/portfolio/app/A004.png';
import appA005 from './../assets/img/portfolio/app/A005.png';
import appA006 from './../assets/img/portfolio/app/A006.png';
import appA007 from './../assets/img/portfolio/app/A007.png';
export const skill1 = [
    {
        name: "React Js",
        percent: "94",
    },
    {
        name: "React Native",
        percent: "94",
    },
    {
        name: "Next Js",
        percent: "92",
    },
    {
        name: "Express Js",
        percent: "92",
    },
    {
        name: "Bootstrap / Tailwindcss / Materialize",
        percent: "99",
    },
    {
        name: "Angular",
        percent: "80",
    },
    {
        name: "Vue Js",
        percent: "65",
    },
    // {
    //     name: "Python",
    //     percent: "60",
    // },
    // {
    //     name: "Django",
    //     percent: "60",
    // },
    // {
    //     name: "Adobe XD",
    //     percent: "96",
    // },
    // {
    //     name: "Wordpress/Squirespace",
    //     percent: "85",
    // },
];
export const skill2 = [
    {
        name: "HTML / CSS (SASS)",
        percent: "99",
    },
    {
        name: "PHP",
        percent: "90",
    },
    {
        name: "Laravel",
        percent: "93",
    },
    {
        name: "Codeigniter",
        percent: "90",
    },
    {
        name: "Javascript / Typescript / Jquery",
        percent: "90",
    },
    {
        name: "Node Js",
        percent: "87",
    },
    {
        name: "MySQL / MongoDB/ DynamoDB",
        percent: "93",
    },
];
export const webs = [
    {
        'id_no': '1007',
        'port_name': 'Oraclesee',
        'port_paragraph': 'www.oraclesee.com',
        'port_link': 'http://www.oraclesee.com/',
        'port_type': '1',
        'image': web1007
    },
    {
        'id_no': '1003',
        'port_name': 'Icemax Inc.',
        'port_paragraph': 'www.icemax.net',
        'port_link': 'http://www.icemax.net/',
        'port_type': '1',
        'image': web1003
    },
    {
        'id_no': '1008',
        'port_name': 'VIP On The Go',
        'port_paragraph': 'www.viponthego.com',
        'port_link': 'http://viponthego.com/',
        'port_type': '1',
        'image': web1008
    },
    {
        'id_no': '1001',
        'port_name': 'Balkan Manila Restaurant',
        'port_paragraph': 'www.balkanmanila.com',
        'port_link': 'http://balkanmanila.com/',
        'port_type': '1',
        'image': web1001
    },
    {
        'id_no': '1002',
        'port_name': 'Datang Jardin Restaurant',
        'port_paragraph': 'www.datanghub.com',
        'port_link': 'http://datanghub.com/jardin/',
        'port_type': '1',
        'image': web1002
    },
    {
        'id_no': '1005',
        'port_name': 'Everest Capital',
        'port_paragraph': 'www.everestcaps.com',
        'port_link': 'http://www.everestcaps.com/',
        'port_type': '1',
        'image': web1005
    },
    {
        'id_no': '1006',
        'port_name': 'Ark Premium Trading',
        'port_paragraph': 'www.arkpremiumtrading.com',
        'port_link': 'http://arkpremiumtrading.com/',
        'port_type': '1',
        'image': web1006
    },
    {
        'id_no': '1009',
        'port_name': 'Trackmate.ph',
        'port_paragraph': 'www.trackmate.ph',
        'port_link': 'http://trackmate.ph/',
        'port_type': '1',
        'image': web1009
    },
    {
        'id_no': '1010',
        'port_name': 'Halo Bistro',
        'port_paragraph': 'www.halobarbistro.ph',
        'port_link': 'http://halobarbistro.ca/',
        'port_type': '1',
        'image': web1010
    },
    {
        'id_no': '1013',
        'port_name': 'Circle Pay',
        'port_paragraph': 'www.circlepay.ph',
        'port_link': 'https://circlepay.ph/',
        'port_type': '1',
        'image': web1013
    },
    {
        'id_no': '1014',
        'port_name': 'Fgocard',
        'port_paragraph': 'www.fgocard.com',
        'port_link': 'https://fgocard.com/',
        'port_type': '1',
        'image': web1014
    },
    {
        'id_no': '1012',
        'port_name': 'FGate247',
        'port_paragraph': 'www.fgate247.com',
        'port_link': 'https://fgate247.com/',
        'port_type': '1',
        'image': web1012
    },
    {
        'id_no': '1016',
        'port_name': 'Payasec.com',
        'port_paragraph': 'www.payasec.com',
        'port_link': 'https://payasec.com/',
        'port_type': '1',
        'image': web1016
    },
    {
        'id_no': '1017',
        'port_name': 'Ogolez.com',
        'port_paragraph': 'www.ogolez.com',
        'port_link': 'https://www.ogolez.com/',
        'port_type': '1',
        'image': web1017
    },
    {
        'id_no': '1011',
        'port_name': 'Vmart.ph',
        'port_paragraph': 'www.vmart.ph',
        'port_link': 'http://vmart.ph/',
        'port_type': '1',
        'image': web1011
    },
    {
        'id_no': '1018',
        'port_name': 'HiredHelpr',
        'port_paragraph': 'www.hiredhelpr.ph',
        'port_link': 'https://hiredhelpr.com/',
        'port_type': '1',
        'image': web1018
    },
    {
        'id_no': '1015',
        'port_name': 'APINGweb',
        'port_paragraph': 'www.apingweb.com',
        'port_link': 'https://apingweb.com/',
        'port_type': '1',
        'image': web1015
    },
];
export const apps = [
    {
        'id_no': 'A003',
        'port_name': 'Fgo Card Application',
        'port_link': 'https://play.google.com/store/apps/details?id=fgocard.com&hl=en',
        'port_link_ios': 'None',
        'port_type': '3',
        'image': appA003
    },
    {
        'id_no': 'A004',
        'port_name': 'Vmart.ph',
        'port_link': 'https://play.google.com/store/apps/details?id=com.got.vmart',
        'port_link_ios': 'https://apps.apple.com/app/id1544008202#?platform=iphone',
        'port_type': '3',
        'image': appA004
    },
    {
        'id_no': 'A006',
        'port_name': 'HiredHelpr - Swimming Pool',
        'port_link': 'https://play.google.com/store/apps/details?id=com.hiredhelpr.poolpro',
        'port_link_ios': 'https://apps.apple.com/us/app/hiredhelpr-swimming-pool/id1501893764',
        'port_type': '3',
        'image': appA006
    },
    {
        'id_no': 'A005',
        'port_name': 'Fanbasis',
        'port_link': 'None',
        'port_link_ios': 'https://apps.apple.com/us/app/fanbasis/id1585599824#?platform=iphone',
        'port_type': '3',
        'image': appA005
    },
    {
        'id_no': 'A007',
        'port_name': 'HiredHelpr - Landscaping',
        'port_link': 'https://play.google.com/store/apps/details?id=com.hiredhelpr.lawnpro',
        'port_link_ios': 'https://apps.apple.com/us/app/hiredhelpr-landscaping/id1661757097',
        'port_type': '3',
        'image': appA007
    },
];