/* This example requires Tailwind CSS v2.0+ */
import { useEffect, useState } from 'react'
import Nav from './component/Nav'
import thatsme from './../assets/img/thatsme.png'
import github from './../assets/img/github-logo.png'
import linkedin from './../assets/img/linkedin-icon-2.svg'
import { skill1, skill2, webs, apps } from './../helper/portfolio'
// import vincent_marc_villafuerte_2022 from './../assets/document/vincent_marc_villafuerte_2022.pdf'
import vincent_marc_villafuerte_2022 from './../assets/document/vincent_marc_villafuerte_2022_v4.pdf'
import ImgsViewer from "react-images-viewer";
export default function Example() {
    const [show, setShow] = useState("web_app")
    const [carousel, setCarousel] = useState(true)
    const [imageview, setImageView] = useState(false)
    const [imgtoview, setImgToView] = useState([])
    useEffect(() => {
        const interval =
            setTimeout(() => {
                if (carousel) {
                    if (show === "web_app") {
                        setShow("mobile_app")
                    } else {
                        setShow("web_app")
                    }
                }
            }, 7000);
        return () => clearInterval(interval);
    }, [carousel, show]);
    return (
        <>
            <Nav />
            <div>
                <div className="max-w-6xl mx-auto">
                    <div className="grid items-center grid-cols-8 gap-4" >
                        <div className="col-span-8 lg:col-span-4">
                            <div className="mx-auto max-w-[600px] p-16">
                                <h1 className="mb-5 text-5xl font-bold lg:text-7xl md:text-6xl text-gray font-open">Hi there!</h1>
                                <h3 className="text-2xl font-regular lg:text-4xl md:text-3xl text-gray font-open">
                                    My name is Vince,
                                </h3>
                                <h3 className="text-2xl font-regular lg:text-4xl text-gray font-open">
                                    I'm a Full Stack Developer
                                    & this is my portfolio.
                                </h3>
                            </div>
                        </div>
                        <div className="col-span-8 lg:col-span-4">
                            <img
                                className="block mx-auto px-7"
                                src={thatsme}
                                alt="logo"
                            />
                        </div>
                    </div>
                </div>
                <div className="bg-neutral-700" id="about">
                    <div className="max-w-5xl px-4 py-16 mx-auto">
                        <h1 className="text-2xl font-semibold text-center text-white font-mont">Vincent Marc Villafuerte</h1>
                        <h1 className="text-lg font-semibold text-center text-teal-500 font-mont">Full Stack Developer / Ninja</h1>
                        <p className="text-sm text-center text-white font-regular font-mont my-7">
                            Vince is a full-stack developer focused on web app and application development. He has years of experience creating solutions for companies where problem-solving and high attention to detail. Vince has extensive experience with Node.js, PHP, Laravel, Express.js , React.js, and React-native and is keen to work with new technologies. He is part of the team in the continuous development of various local software projects and flexible when it comes to working on different timezone and either work alone.
                        </p>
                        <a href={vincent_marc_villafuerte_2022}>
                            <button type="button" className="transition duration-200 font-mont text-white bg-teal-500 hover:bg-teal-600 font-bold rounded-lg text-sm px-5 py-2.5 mx-auto block ">DOWNLOAD RESUME</button>
                        </a>
                    </div>
                </div>
            </div>
            {/* ------------------------------- */}
            <div>
                <div className="bg-neutral-900" id="skills">
                    <div className="max-w-5xl px-4 py-16 mx-auto">
                        <h1 className="text-4xl font-semibold text-center text-white font-mont">MY MAIN SKILLS</h1>
                        <div className="grid items-center grid-cols-2 gap-0 my-5 lg:gap-10" >
                            <div className="col-span-2 lg:col-span-1">
                                <div className='mt-7'>
                                    <div class="flex justify-between mb-1">
                                        <span class="text-lg font-medium text-white font-mont">FRONTEND</span>
                                    </div>
                                    <div class="w-full bg-gray-200 rounded-full h-3 dark:bg-gray-700">
                                        <div class="bg-red-500 h-3 rounded-full" style={{ width: 97 + "%" }}></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-2 lg:col-span-1">
                                <div className='mt-7'>
                                    <div class="flex justify-between mb-1">
                                        <span class="text-lg font-medium text-white font-mont">BACKEND</span>
                                    </div>
                                    <div class="w-full bg-gray-200 rounded-full h-3 dark:bg-gray-700">
                                        <div class="bg-blue-500 h-3 rounded-full" style={{ width: 75 + "%" }}></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-2 lg:col-span-1">
                                {
                                    skill2.map((item, index) => (
                                        <div className='my-7' key={index}>
                                            <div class="flex  content justify-between mb-1 ">
                                                <span class="text-lg font-medium text-white font-mont content flex items-center">{item.name}
                                                </span>
                                            </div>
                                            <div class="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                                                <div class="bg-teal-500 h-2.5 rounded-full" style={{ width: item.percent + "%" }}></div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="col-span-2 lg:col-span-1">
                                {
                                    skill1.map((item, index) => (
                                        <div className='my-7' key={index}>
                                            <div class="flex justify-between mb-1">
                                                <span class="text-lg font-medium text-white font-mont block">{item.name}</span>
                                            </div>
                                            <div class="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                                                <div class="bg-teal-500 h-2.5 rounded-full" style={{ width: item.percent + "%" }} ></div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="max-w-6xl py-16 mx-auto" id="projects">
                    <h1 className="mb-10 text-4xl font-semibold text-center font-mont text-gray">PROJECTS</h1>
                    <div className='max-w-2xl px-4 mx-auto'>
                        <div className="grid items-center grid-cols-2 my-5 gap-7" >
                            <div className="col-span-1">
                                <button type="button" onClick={() => {
                                    setShow("web_app")
                                    setCarousel(false)
                                }} className={`transition duration-200 font-mont w-full font-bold text-lg py-2.5 mx-auto block`}>
                                    WEB APPS
                                    <div class={`pb-2.5 ${show === "web_app" ? "bg-teal-600" : "bg-slate-300"} rounded-lg mt-2.5`}></div>
                                </button>
                            </div>
                            <div className="col-span-1">
                                <button type="button" onClick={() => {
                                    setShow("mobile_app")
                                    setCarousel(false)
                                }} className={`transition duration-200 font-mont w-full font-bold text-lg py-2.5 mx-auto block`}>
                                    MOBILE APPS
                                    <div class={`pb-2.5 ${show === "mobile_app" ? "bg-teal-600" : "bg-slate-300"} rounded-lg mt-2.5`}></div>
                                </button>
                            </div>
                        </div>
                    </div>
                    {
                        (show === "web_app")
                            ?
                            <div className="grid items-center grid-cols-1 my-10 lg:grid-cols-3 md:grid-cols-4 gap-7" >
                                {
                                    ([...webs].reverse()).map((item, index) => (
                                        <div className="col-span-1 lg:col-span-1 md:col-span-2">
                                            <img
                                                className="block mx-auto max-w-[300px] cursor-pointer"
                                                src={item.image}
                                                alt="logo"
                                                onClick={() => {
                                                    setCarousel(false)
                                                    setImageView(true)
                                                    setImgToView([{ src: item.image }])
                                                }}
                                            />
                                            <h1 className="text-lg font-semibold text-center font-mont text-gray ">{item.port_name}</h1>
                                            <p className="text-sm font-medium text-center font-mont text-gray ">Link: <a href={item.port_link}><span class="text-teal-500">{item.port_paragraph}</span></a></p>
                                        </div>
                                    ))
                                }
                            </div>
                            : null
                    }
                    {
                        (show === "mobile_app")
                            ?
                            <div className="grid grid-cols-1 gap-10 my-10 md:grid-cols-3 sm:grid-cols-4" >
                                {
                                    ([...apps].reverse()).map((item, index) => (
                                        <div className="col-span-1 md:col-span-1 sm:col-span-2">
                                            <img
                                                className="block mx-auto max-w-[300px] cursor-pointer"
                                                src={item.image}
                                                alt="logo"
                                                onClick={() => {
                                                    setCarousel(false)
                                                    setImageView(true)
                                                    setImgToView([{ src: item.image }])
                                                }}
                                            />
                                            <h1 className="text-lg font-semibold text-center font-mont text-gray ">{item.port_name}</h1>
                                            {
                                                item.port_link !== "None"
                                                    ? <p className="text-sm font-medium text-center font-mont text-gray ">Android: <a href={item.port_link}><span class="text-teal-500">LINK</span></a></p>
                                                    : null
                                            }
                                            {
                                                item.port_link_ios !== "None"
                                                    ? <p className="text-sm font-medium text-center font-mont text-gray ">IOS: <a href={item.port_link_ios}><span class="text-teal-500">LINK</span></a></p>
                                                    : null
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                            : null
                    }
                </div>
            </div>
            <div className="bg-neutral-700" id="contact">
                <div className="max-w-md px-4 mx-auto py-11 ">
                    <div className='mb-5'>
                        <img
                            className="block max-w-[40px] mx-auto my-3"
                            src={linkedin}
                            alt="logo"
                        />
                        <a href="https://www.linkedin.com/in/vincent-marc-villafuerte-528b27133/">
                            <p className="text-sm font-medium text-center text-teal-500 font-mont">
                                linkedin.com/in/vincent-marc-villafuerte-528b27133
                            </p>
                        </a>
                    </div>
                    <div className='mb-5'>
                        <img
                            className="block max-w-[40px] mx-auto my-3"
                            src={github}
                            alt="logo"
                        />
                        <a href="https://github.com/vincemavill">
                            <p className="text-sm font-medium text-center text-teal-500 font-mont">
                                github.com/vincemavill
                            </p>
                        </a>
                    </div>
                    <div className='mb-5'>
                        <h1 className="text-lg font-semibold text-center text-white font-mont">Address</h1>
                        <p className="text-sm font-medium text-center text-teal-500 font-mont">
                            68-D Mindanao Ave. corner San Isidro St.
                            Brgy Sto. Nino Quezon City. Philippines
                        </p>
                    </div>
                    <div className='mb-5'>
                        <h1 className="text-lg font-semibold text-center text-white font-mont">Email</h1>
                        <p className="text-sm font-medium text-center text-teal-500 font-mont">
                            vincemavill@gmail.com
                        </p>
                    </div>
                    <div>
                        <h1 className="text-lg font-semibold text-center text-white font-mont">Phone Number</h1>
                        <p className="text-sm font-medium text-center text-teal-500 font-mont">
                            09152723521
                        </p>
                    </div>
                </div>
            </div>
            <div>
                <div className="bg-neutral-900 py-7">
                    <p className="text-xs font-medium text-center text-white font-mont"> Made by <a href="https://github.com/vincemavill"><span className="text-teal-500">Vincent Marc Villafuerte</span></a> v 4.1.3 </p>
                </div>
            </div>
            <ImgsViewer
                imgs={imgtoview}
                currImg={0}
                isOpen={imageview}
                onClose={() => {
                    setImageView(false)
                }}
            />
        </>
    )
}
