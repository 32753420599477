/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import mylogo from './../../assets/img/logovince.svg'
import AnchorLink from 'react-anchor-link-smooth-scroll-v2'
const navigation_left = [
    { name: 'ABOUT', href: '#about', current: false },
    { name: 'SKILLS', href: '#skills', current: false },
]
const navigation_right = [
    { name: 'PROJECTS', href: '#projects', current: false },
    { name: 'CONTACT', href: '#contact', current: false },
]
const navigation = [
    { name: 'ABOUT', href: '#about', current: false },
    { name: 'SKILLS', href: '#skills', current: false },
    { name: 'PROJECTS', href: '#projects', current: false },
    { name: 'CONTACT', href: '#contact', current: false },
]
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
export default function Example() {
    return (
        <Disclosure as="nav" className="bg-teal-500">
            {({ open }) => (
                <>
                    <div className="max-w-6xl mx-auto px-2 sm:px-6 lg:px-8 ">
                        <div className="relative flex items-center justify-between h-20">
                            <div className="absolute inset-y-0 left-0 flex items-center md:hidden">
                                <Disclosure.Button className="transition duration-200 inline-flex items-center justify-center p-2 rounded-md text-white hover:text-gray-500 hover:bg-teal-300">
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                                    ) : (
                                        <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                                    )}
                                </Disclosure.Button>
                            </div>
                            <div className="flex-1 flex items-center justify-center md:items-stretch md:justify-start">
                                <div className="hidden md:block md:ml-6">
                                    <div className="flex space-x-6">
                                        {navigation_left.map((item) => (
                                            <AnchorLink
                                                key={item.name}
                                                href={item.href}
                                                className={classNames(
                                                    item.current ? 'text-white' : 'text-white hover:text-gray-500',
                                                    'px-6 py-2 text-sm font-semibold font-mont transition duration-200'
                                                )}
                                                aria-current={item.current ? 'page' : undefined}
                                            >
                                                {item.name}
                                            </AnchorLink>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="flex-shrink-0 flex items-center">
                                <img
                                    className="block max-h-[60px] w-auto"
                                    src={mylogo}
                                    alt="logo"
                                />
                            </div>
                            <div className="flex-1 flex items-center justify-center md:items-stretch md:justify-end">
                                <div className="hidden md:block md:ml-6">
                                    <div className="flex space-x-6">
                                        {navigation_right.map((item) => (
                                            <AnchorLink
                                                key={item.name}
                                                href={item.href}
                                                className={classNames(
                                                    item.current ? 'text-white' : 'text-white hover:text-gray-500',
                                                    'px-6 py-2 text-sm font-semibold font-mont transition duration-200'
                                                )}
                                                aria-current={item.current ? 'page' : undefined}
                                            >
                                                {item.name}
                                            </AnchorLink>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Disclosure.Panel className="md:hidden">
                        <div className="px-2 pt-2 pb-3 space-y-1">
                            {navigation.map((item) => (
                                <Disclosure.Button
                                    key={item.name}
                                    as="a"
                                    href={item.href}
                                    className={classNames(
                                        item.current ? 'text-white' : 'text-white hover:text-gray-500 hover:bg-teal-300',
                                        'block px-3 py-2 rounded-md text-sm font-semibold font-mont transition duration-200'
                                    )}
                                    aria-current={item.current ? 'page' : undefined}
                                >
                                    {item.name}
                                </Disclosure.Button>
                            ))}
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    )
}
