import React, { useEffect, useState } from "react";
import "../style/App.css";
import { Route, Routes, Navigate } from "react-router-dom";

import Home from "../pages/Home";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
    </Routes>
  );
}